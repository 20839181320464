<template>
  <div>
    <div class="uk-text-left">
      <h2 class="uk-margin-remove"> {{orgName}} </h2>
    </div>
    <h3 class="uk-text-left uk-margin-small-top">{{title || `${$t('Actions.Select')} ${$t('enums.ContentType.AD')}`}}</h3>
    <hr />
    <span class="fixed" v-if="fetching" uk-spinner="ratio: 2"> </span>
    <div 
      class="uk-grid-small uk-width-1-1 uk-grid-match uk-padding-small fixed"
      uk-grid
      uk-scrollspy="target: > div; cls: uk-animation-scale-up; offset-top: 100;"
      v-if="!fetching"
      uk-overflow-auto
    >
      <div 
          v-for="(ad, i) in ads"
          :key="i"
          class="uk-grid-item-match uk-width-1-6@l uk-width-1-4@m uk-width-1-2@s"
        >
        <AdCard v-if="!isAdBlockerEnabled" :ad="ad" :selected-ads="selectedAds" :selected="isSelected" :selected-file="selectedFile.id" :ad-type="adType" @click="selectedFiles( ad )"/>
      </div>
    </div>
    <hr>
    <button class="uk-button uk-button-default uk-margin-small-right uk-modal-close" type="button" @click="cancel"> {{ $t('Actions.Cancel') }} </button>
    <button v-if="!submit" :disabled="!selectedFile || ( selectedAds.length === 0 && adType === 'Video Ad')" class="uk-button uk-button-primary uk-margin-small-right" @click="select" type="button"> {{ buttonText }} </button>
    <button v-else class="uk-button uk-button-primary uk-margin-small-right" type="button" :disabled="true"  > <span uk-spinner="ratio: 1"> </span> </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AdCard from '../cards/AdCard';
import { isAdBlocker } from '@/utils/adBlocker';

export default {
  name: 'ModalAdPicker',
  components: { AdCard },
  props: {
    title: { type: String, default: ''},
    submit: { type: Boolean, default: false },
    buttonText: { type: String, default: 'Select' },
    adType: { type: String, default: '' },
    adsList: { type: Array, default: () => [] }
  },
  data () {
    return {
      fetching: false,
      selectedFile: '',
      selectedAds: [...this.adsList],
      isSelected: false,
      isAdBlockerEnabled: false,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      ads: state => (state.venom.org.ads) ? state.venom.org.ads.nodes : [],
      orgName: state => state.venom.org.name,
      pageInfo: state => (state.venom.org.ads) ? state.venom.org.ads.pageInfo : {}
    }),
  },
  async mounted () {
    if ( await isAdBlocker() ) {
      this.isAdBlockerEnabled = true;
    } else
      this.fetchOrgAds( false );
  },
  watch: {
    adsList: {
      handler ( newVal ) {
        if ( newVal )
          this.selectedAds = [...newVal];
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async fetchOrgAds( isNextPage ) {
       this.fetching = true;
      await this.$store.dispatch('fetchAds', {
        orgId: this.orgId,
        first: 25,
        after: isNextPage ? ( this.pageInfo.hasNextPage ? this.pageInfo?.endCursor : '' ) : '',
        isNextPage: isNextPage,
        adType: this.adType ? 'Video Ad': ''
      });   
      this.fetching = false;

      if ( this.pageInfo?.hasNextPage) {
        this.fetchOrgAds( true );
      }
    },
    select () {
      this.$emit('selectAd', this.adType.length === 0 ? this.selectedFile : this.selectedAds );
      this.selectedFile = '';
    },
    cancel () {
      this.$emit('cancel');
      this.selectedFile = '';
      this.selectedAds = this.adsList.splice();
      this.fetchOrgAds( false );
    },
    selectedFiles ( ad ) {
        this.selectedFile = ad ;
        if( this.selectedFile && !this.adType  ) {
          this.isSelected = true;
        }
        else if ( this.selectedAds.includes( ad ) )
          this.selectedAds.splice( this.selectedAds.indexOf( ad ), 1 );
        else if ( this.adsList.find( obj => obj.id === ad.id ) ) {
          this.adsList.splice( this.adsList.indexOf( this.adsList.find( obj => obj.id === ad.id ) ), 1 );
        } else
          this.selectedAds.push( ad );
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed {
  height: 55vh;
}
</style>