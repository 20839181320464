<template>
  <div class="uk-card uk-card-default uk-text-center uk-border-rounded uk-card-hover ad uk-height-small" :class="{ 'selected': isSelected }" @click="$emit( 'click' )">
    <ImageOrAbbrevation
      :src="image"
      :alt="ad.title"
      width="85px"
      height="45px"
      class="uk-margin-top"/>
      <p class="uk-text-truncate uk-dark uk-margin-small uk-margin-small-left uk-margin-small-right uk-card-title title">{{ ad.title }}<br />
      <span class="uk-text-light uk-text-small uk-margin-remove-bottom">{{ typeName }}</span><br />
      <span class="uk-text-light uk-text-small uk-margin-remove-top">{{ ad.lastUpdated | date }}</span><br />
    </p>
  </div>
</template>
<script>
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';

export default {
  name: 'AdCard',
  components: { ImageOrAbbrevation },
  props: {
    ad: { type: Object, required: true },
    selectedAds: { type: Array },
    adType: { type: String, default: '' },
    selectedFile: { type: String, default: '' },
    selected: { type: Boolean, default: false }
  },
  computed: {
    isSelected () {
      if ( this.selectedAds.length > 0 ) {
        return this.selectedAds.some(a => a.id === this.ad.id );
      }
      else if ( this.selectedAds.length === 0  && !this.adType ) {
        return this.selectedFile === this.ad.id ;
      }
      else if ( this.selectedAds.length === 0 && this.adType ) {
        return false ;
      } else 
      return false ;
    },
    image () { return this.ad.banner?.url || ''; },
    typeName () {
      switch ( this.ad.__typename ) {
        case 'VideoAd': return this.$t( 'AdTypes.Video' );
        case 'PromotedAd': return this.$t( 'AdTypes.Promoted' );
        case 'BannerAd': return this.$t( 'AdTypes.Banner' );
        default: return 'Unknown';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ad {
  cursor: pointer;
}

.selected {
  border: 1px solid var(--app-primary-color);
}

.title {
  font-size: 14px;
}

</style>
